<template>
  <div v-loading="detailLoading">
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>销售数据</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/sales' }">报单</el-breadcrumb-item>
        <el-breadcrumb-item>编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item class="" label="类型：" label-position="left">
              <el-select
                v-model="form.type"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="用户昵称："
              label-position="left"
              prop="nickname"
            >
              <el-input v-model="form.nickname" style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="课程名称："
              label-position="left"
              prop="course"
            >
              <el-input v-model="form.course" style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地区：" label-position="left" prop="area">
              <el-input v-model="form.area" style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="支付方式：" label-position="left">
              <el-select
                v-model="form.payType"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in payType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年级：" label-position="left" prop="grade">
              <el-select
                v-model="form.grade"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in grades"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 50px">
          <el-col :span="12">
            <el-form-item label="支付金额：" label-position="left" prop="price">
              <el-input
                v-model.number="form.price"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              class="input"
              label="用户ID："
              label-position="left"
              prop="uid"
            >
              <div>
                <div class="flex">
                  <el-select
                    slot="prepend"
                    v-model="searchType"
                    placeholder="选择类型"
                    size="mini"
                    @change="changeSearch"
                  >
                    <el-option :value="1" label="微信昵称"></el-option>
                    <el-option :value="2" label="UID"></el-option>
                  </el-select>
                  <el-select
                    v-model="studentName"
                    :loading="loading"
                    :remote-method="searchStudent"
                    clearable
                    filterable
                    placeholder="请输入关键词"
                    remote
                    reserve-keyword
                    size="mini"
                    @change="selectUser"
                    @clear="clearUser"
                  >
                    <el-option
                      v-for="item in users"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      style="height: 40px; line-height: 40px"
                    >
                      <div
                        style="
                          display: flex;
                          justify-content: start;
                          align-items: center;
                          height: 40px;
                          line-height: 40px;
                        "
                      >
                        <span style="margin-right: 10px"> {{ item.id }} </span>
                        <el-avatar
                          :src="item.avatar_url"
                          size="small"
                        ></el-avatar>
                        <span style="margin-left: 10px">{{ item.name }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </div>
                <div v-if="notData" class="margin-t-sm">暂无数据</div>
                <div
                  v-if="JSON.stringify(selectCurrentUser) !== '{}'"
                  style="
                    margin-top: 12px;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    border-radius: 4px;
                    margin: 4px;
                  "
                >
                  <el-avatar
                    :src="selectCurrentUser.avatar_url"
                    size="small"
                  ></el-avatar>
                  <span style="margin-left: 4px">{{
                    selectCurrentUser.name
                  }}</span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开单微信号：" label-position="left" prop="wechat">
              <el-input
                  placeholder="例：xxyw2208"
                  v-model="form.wechat"
                  :rows="5"
                  resize="none"
                  style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复购归属：" label-position="left">
              <el-select
                v-model="form.repurchaseUserId"
                filterable
                placeholder="请选择复购归属"
              >
                <el-option
                  v-for="item in salesAttribution"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收款企业：" label-position="left" prop="enterpriseId">
              <el-select
                v-model="form.enterpriseId"
                filterable
                placeholder="请选择收款企业"
                clearable
              >
                <el-option
                  v-for="item in enterprises"
                  :key="item.enterprise_id"
                  :label="item.name"
                  :value="item.enterprise_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="加粉日期：" label-position="left" prop="addAt">
              <el-date-picker
                v-model="form.addAt"
                placeholder="选择日期"
                size="mini"
                style="width: 100%"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱：" label-position="left">
              <el-input
                v-model="form.email"
                :rows="5"
                resize="none"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              v-show="form.isAddress === 1"
              label="收件人姓名："
              label-position="left"
            >
              <el-input
                v-model="form.userName"
                :rows="5"
                resize="none"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-show="form.isAddress === 1"
              label="收件人电话："
              label-position="left"
            >
              <el-input
                v-model="form.phone"
                :rows="5"
                resize="none"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
              class="input"
              label="邮寄书本："
              label-position="left"
            >
              <el-switch
                v-model="form.isAddress"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
              <span style="margin-left: 5px; font-size: 12px">{{
                form.isAddress === 1 ? "需要邮寄" : "不需要邮寄"
              }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              v-show="form.isAddress === 1"
              label="收货地址："
              label-position="left"
            >
              <el-input
                v-model="form.address"
                :rows="5"
                resize="none"
                style="width: 100%"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注：" label-position="left">
              <el-input
                v-model="form.remark"
                :rows="5"
                resize="none"
                style="width: 100%"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="附件：" label-position="left">
              <paste-image
                :picArray="picArray"
                upload-path="sales"
                @onDelete="onDelete"
                @onSuccess="onSuccess"
              ></paste-image>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item class="input" label="状态：" label-position="left">
              <el-switch
                v-model="form.status"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
              <span style="margin-left: 5px; font-size: 12px">{{
                form.status === 0 ? "需要处理" : "不需要处理"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              class="input"
              label="开通课程："
              label-position="left"
            >
              <el-switch
                v-model="form.hasCourse"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
              <span style="margin-left: 5px; font-size: 12px">{{
                form.hasCourse === 0 ? "不需要开通课程" : "需要开通课程权限"
              }}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <template v-if="form.hasCourse === 1">
          <el-row>
            <el-col :span="24">
              <el-form-item label="开通信息：" label-position="left">
                <sales-course-user @onAdd="addUserCourse"></sales-course-user>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
            v-for="(userCourse, index) in form.userCourse"
            :key="index"
            label=""
            label-position="left"
          >
            <el-descriptions :column="2" border size="mini" title="">
              <template slot="extra">
                <el-button
                  size="small"
                  type="danger"
                  @click="deleteUserCourse(index)"
                  >删除
                </el-button>
              </template>
              <el-descriptions-item label="用户ID"
                >{{ userCourse.user_id }}
              </el-descriptions-item>
              <el-descriptions-item label="用户名">
                <div>
                  <el-avatar
                    :src="userCourse.user_name_avatar_url"
                    size="small"
                  ></el-avatar>
                </div>
                <div>{{ userCourse.user_name }}</div>
              </el-descriptions-item>
              <el-descriptions-item label="课程ID"
                >{{ userCourse.course_id }}
              </el-descriptions-item>
              <el-descriptions-item label="课程名">
                <div>
                  <el-image
                    :src="imageUrl + userCourse.course_pic"
                    fit="contain"
                    style="width: 100px"
                  ></el-image>
                </div>
                <div>{{ userCourse.course_name }}</div>
              </el-descriptions-item>
              <el-descriptions-item label="课程类型"
                >{{
                  userCourse.course_type === "checkCourse"
                    ? "阅读日程"
                    : "录播课程"
                }}
              </el-descriptions-item>

              <template v-if="userCourse.course_type === 'checkCourse'">
                <el-descriptions-item :span="2" label="开始更新时间"
                  >{{ userCourse.start_at }}
                </el-descriptions-item>
                <el-descriptions-item :span="2" label="是否全部计划">
                  <el-tag v-if="userCourse.is_all" size="small">是</el-tag>
                  <el-tag v-else size="small" type="danger">否</el-tag>
                </el-descriptions-item>
                <template v-if="!userCourse.is_all">
                  <el-descriptions-item label="开始计划天数"
                    >{{ userCourse.start_plan_day }}
                  </el-descriptions-item>
                  <el-descriptions-item label="结束计划天数"
                    >{{ userCourse.end_plan_day }}
                  </el-descriptions-item>
                </template>
                <el-descriptions-item :span="2" label="更新周期"
                  >{{ userCourse.update_day }}
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </el-form-item>
        </template>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <router-link class="router-link" to="/sales">
            <el-button size="small" style="margin-left: 10px">取消</el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PasteImage from "../../../components/pasteImage/index";
import SalesCourseUser from "../../../components/sales/salesCourseUser";

export default {
  name: "edit",
  components: { SalesCourseUser, PasteImage },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
    }),
  },
  data() {
    return {
      imageUrl: config.imageUrl,
      detailLoading: false,
      salesAttribution: [],
      enterprises: [],
      statusTxt: "上架",
      loading: false,
      picArray: [],
      check: 0,
      courseStatus: 0,
      form: {
        id: 0,
        type: 1,
        pic: [],
        nickname: "",
        course: "",
        price: 0,
        phone: "",
        email: "",
        wechat: "",
        userName: "",
        address: "",
        remark: "",
        payType: 1,
        refundAccount: "",
        status: 0,
        hasCourse: 1,
        grade: "",
        area: "",
        isAddress: 1,
        userCourse: [],
        addAt: "",
        uid: 0,
        repurchaseUserId: 1,
        enterpriseId: '',
      },

      grades: [
        {
          value: "一年级",
          label: "一年级",
        },
        {
          value: "二年级",
          label: "二年级",
        },
        {
          value: "三年级",
          label: "三年级",
        },
        {
          value: "四年级",
          label: "四年级",
        },
        {
          value: "五年级",
          label: "五年级",
        },
        {
          value: "六年级",
          label: "六年级",
        },
        {
          value: "七年级",
          label: "七年级",
        },
        {
          value: "八年级",
          label: "八年级",
        },
        {
          value: "九年级",
          label: "九年级",
        },
      ],
      rules: {
        nickname: [
          { required: true, message: "请输入用户昵称", trigger: "blur" },
          {
            min: 2,
            max: 24,
            message: "长度在 2 到 24 个字符",
            trigger: "blur",
          },
        ],
        course: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入支付金额", trigger: "blur" },
          { type: "number", message: "请输入正确的金额", trigger: "blur" },
        ],
        grade: [{ required: true, message: "请选择年级", trigger: "blur" }],
        area: [{ required: true, message: "请填写地区", trigger: "blur" }],
        addAt: [{ required: true, message: "请填写日期", trigger: "blur" }],
        uid: [{ required: true, message: "请选择用户ID", trigger: "change" }],
        enterpriseId: [{ required: true, message: "请选择收款企业", trigger: "blur" }],
      },
      payType: [
        {
          value: 1,
          label: "微信支付",
        },
        {
          value: 2,
          label: "支付宝支付",
        },
      ],
      options: [
        {
          value: 1,
          label: "新签",
        },
        {
          value: 2,
          label: "退费",
        },
        {
          value: 3,
          label: "转介绍",
        },
        {
          value: 4,
          label: "扩科目",
        },
        {
          value: 5,
          label: "续费",
        },
        {
          value: 6,
          label: "百度",
        },
        {
          value: 7,
          label: "头条",
        },
      ],
      searchType: 2,
      selectCurrentUser: {},
      users: [],
      studentName: "",
      notData: false,
    };
  },
  methods: {
    ...mapActions("sales", [
      "addSales",
      "getSalesDetail",
      "getSalesUserCourseDetail",
      "selectSalesAttribution",
    ]),
    ...mapActions("user", ["getUserList"]),
    ...mapActions("enterprise",["selectEnterprise"]),
    changeSearch() {},
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
          this.form.userId = item.id;
          this.form.nickname = item.name;
        }
      });
      this.form.uid = id;
    },
    clearUser() {},
    searchStudent(v) {
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = v;
      } else {
        searchData.uid = v;
      }

      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    uploadSuccess(path) {
      this.form.pic = path;
    },
    validateForm(formName) {
      let validate = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          validate = true;
          return true;
        } else {
          validate = false;
          return false;
        }
      });
      return validate;
    },
    async onSubmit() {
      console.log(this.form);

      if (!this.validateForm("form")) {
        this.$message.error("请填写必填选项！");
        return false;
      }

      let _this = this;
      this.loading = true;

      await this.addSales(this.form)
        .then((res) => {
          if (res.ret == 0) {
            _this.$message.success("修改成功！");
            _this.$router.push("/sales");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSuccess(pic) {
      this.form.pic = pic;
    },
    onDelete(pic) {
      this.form.pic = pic;
    },
    setStatus(status) {
      console.log(status);
      if (this.form.status) {
        this.statusTxt = "上架";
      } else {
        this.statusTxt = "下架";
      }
      this.form.status = status;
    },
    addUserCourse(e) {
      this.form.userCourse.push(e);
    },
    deleteUserCourse(i) {
      this.form.userCourse.splice(i, 1);
    },
    async getSalesAttribution() {
      try {
        this.add = true;
        const { data } = await this.selectSalesAttribution();
        this.salesAttribution = data;
      } finally {
        this.add = false;
      }
    },
    async getEnterprises() {
      const { ret, data } = await this.selectEnterprise();
      if(ret === 0) {
        this.enterprises = data;
      }
    },
  },
  async mounted() {
    this.detailLoading = true;

    let id = this.$route.params.id;
    this.form.id = id;
    let detail = await this.getSalesDetail(id);
    let res = await this.getSalesUserCourseDetail({ id: id });

    this.form.userCourse = res.data;

    if (detail.ret === 0) {
      this.form.type = detail.data.type;
      this.form.pic = detail.data.pic;
      this.form.nickname = detail.data.nickname;
      this.form.course = detail.data.course;
      this.form.price = detail.data.price;
      this.form.phone = detail.data.phone;
      this.form.userName = detail.data.user_name;
      this.form.address = detail.data.address;
      this.form.remark = detail.data.remark;
      this.form.payType = detail.data.pay_type;
      this.form.status = detail.data.status;
      this.form.grade = detail.data.grade;
      this.form.area = detail.data.area;
      this.form.email = detail.data.email;
      this.form.wechat = detail.data.wechat;
      this.form.addAt = detail.data.add_at;
      this.form.hasCourse = detail.data.has_course;
      this.form.isAddress = detail.data.is_address;
      this.picArray = detail.data.pic;
      this.check = detail.data.check;
      this.courseStatus = detail.data.course_status;
      this.form.uid = detail.data.uid;
      this.studentName = detail.data.uid;
      this.form.repurchaseUserId = JSON.parse(
        JSON.stringify(detail.data.repurchase_user_id)
      );
      // this.selectCurrentUser = {
      //     name: detail.data.user_name,
      //     id: detail.data.user_id,
      //     avatar_url: detail.data.user_name_avatar_url,
      // }
      this.form.enterpriseId = detail.data.enterprise_id;
      await this.getSalesAttribution();
      await this.getEnterprises();
      console.log(detail.data);
    }
    this.detailLoading = false;
  },
};
</script>

<style scoped></style>